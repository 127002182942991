import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Controls from "../../utility/controls/Controls";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MemberLedgerReportModel } from "../../models/SocietyReportModel";
import { societyReportService } from "../../services/SocietyReportService";
import useForm from "../../utility/hooks/UseForm";
import DownloadIcon from '@mui/icons-material/Download';
import { SelectListModel } from "../../models/ApiResponse";
import { societyBuildingsService } from "../../services/SocietyBuildingsService";
import { societyBuildingUnitsService } from "../../services/SocietyBuildingUnitsService";
import { SocietySubscriptionsModel } from "../../models/SocietySubscriptionsModel";
import { societySubscriptionsService } from "../../services/SocietySubscriptionsService";
import { globalService } from "../../services/GlobalService";
import fileDownload from "js-file-download";
import dayjs from "dayjs";
import { Messages, ROLES } from "../../utility/Config";
import { AuthContext } from "../../utility/context/AuthContext";
import { useSharedNavigation } from "../../utility/context/NavigationContext";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

const MemberLedgerReportForm = () => {
  const { auth } = React.useContext(AuthContext);
  const { societyId, societySubscriptionId }: any = useParams();
  let navigate = useNavigate();
  let isSSSociety = process.env.REACT_APP_BASE_URL.includes("sssociety");
  const [reportData, setReportData] = useState([]);
  const [memberData, setmemberData] = useState([]);
  const [unitNo, setUnitNo] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [buildings, setBuildings] = useState<SelectListModel[]>([]);
  const [buildingUnits, setBuildingUnits] = useState<SelectListModel[]>([]);
  const [societySubscription, setSocietySubscription] =
    useState<SocietySubscriptionsModel>(null);
  const { goToHome } = useSharedNavigation();
  const validate = (fieldValues: MemberLedgerReportModel = values) => {
    let temp: any = { ...errors };
    if ("FromDate" in fieldValues)
      temp.FromDate = fieldValues.FromDate ? "" : "From Date is required.";
    if ("ToDate" in fieldValues)
      temp.ToDate = fieldValues.ToDate ? "" : "To Date is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      societyReportService.initialMemberLedgerReportFieldValues,
      validate,
      societyId
    );

  useEffect(() => {
    if (!globalService.isSocietySelected()) {
      globalService.info(Messages.SocietyUnSelected);
      return goToHome();
    }
    if (buildings.length === 0) getBuildingsForSociety();
    if (!societySubscription) getSocietySubscriptionById();
  }, [societyId]);

  const newReport = () => {
    setValues(societyReportService.initialMemberLedgerReportFieldValues);
  };
  const handleViewClick = () => {
    var model = setFormValue(values);
    societyReportService.memberLedgerReportlist(model).then((response) => {
      const result = response.data;
      let cumulativeSum = 0;
      let cumulativeChgAmtBal = 0;
      let cumulativeNonChgAmt = 0;
      let cumulativeIntAmtBal = 0;
      let cumulativeTaxAmtBal = 0;
      let cumulativeAdvanceBal = 0;
      const updatedData = result.list.map((row: any) => {
        const balanceTotal =
          (row.ChgBal ?? 0) +
          (row.NonChgBal ?? 0) +
          (row.IntBal ?? 0) +
          (row.TaxBal ?? 0) -
          (row.AdvBal ?? 0);
        cumulativeSum += balanceTotal;
        const balanceChg = row.ChgBal ?? 0;
        cumulativeChgAmtBal += balanceChg;
        const balanceNonChgAmt = row.NonChgBal ?? 0;
        cumulativeNonChgAmt += balanceNonChgAmt;
        const balanceIntAmtBal = row.IntBal ?? 0;
        cumulativeIntAmtBal += balanceIntAmtBal;
        const balanceTaxAmtBal = row.TaxBal ?? 0;
        cumulativeTaxAmtBal += balanceTaxAmtBal;
        const balanceAdvanceBal = row.AdvBal ?? 0;
        cumulativeAdvanceBal -= balanceAdvanceBal;
        return {
          ...row,
          CumulativeBalanceTd: cumulativeSum.toFixed(2),
          ChgAmtBal: cumulativeChgAmtBal.toFixed(2),
          NonChgAmtBal: cumulativeNonChgAmt.toFixed(2),
          IntAmtBal: cumulativeIntAmtBal.toFixed(2),
          TaxAmtBal: cumulativeTaxAmtBal.toFixed(2),
          AdvanceBal: cumulativeAdvanceBal.toFixed(2),
        };
      });
      setReportData(updatedData);
      setmemberData(updatedData[0].Member);
      setUnitNo(updatedData[0].BuildingUnit);
      setShowDetails(!values.IsDetails);
    });
  };
  //This is used since in get the null property is not returned
  function setFormValue(model: MemberLedgerReportModel) {
    let newValue = {
      SocietyId: societyId,
      SocietySubscriptionId: societySubscriptionId,
      SocietyBuildingId: model.SocietyBuildingId,
      SocietyBuildingUnitId: model.SocietyBuildingUnitId,
      FromDate: model.FromDate
        ? new Date(model.FromDate)
        : globalService.convertLocalToUTCDate(
            new Date(societySubscription.SubscriptionStart)
          ),
      ToDate: model.ToDate
        ? new Date(model.ToDate)
        : globalService.convertLocalToUTCDate(
            new Date(societySubscription.PaidTillDate)
          ),
      IsDetails: model.IsDetails || false,
    };
    return newValue;
  }

  const getSocietySubscriptionById = () => {
    societySubscriptionsService
      .getById(societySubscriptionId)
      .then((response) => {
        var result = response.data;
        if (result.isSuccess) {
          setSocietySubscription(result.data);
          values.FromDate = globalService.convertLocalToUTCDate(
            new Date(result.data.SubscriptionStart)
          );
          values.ToDate = globalService.convertLocalToUTCDate(
            new Date(result.data.PaidTillDate)
          );
        } else globalService.error(result.message);
      });
  };

  const columnsWithoutDetails: GridColDef[] = [
    { field: "BillNo", headerName: "Doc No", width: 150 },
    {
      field: "BillDate",
      headerName: "Doc Date",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    { field: "Particulars", headerName: "Particulars", flex: 1 },
    {
      field: "Bill",
      headerName: "Bill Amount(Dr)",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        const row = params.row;
        var billAmount = 0;
        const isBill =
          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
        billAmount =
          isBill && row.BillNo !== ""
            ? (row.ChgAmt ?? 0) +
              (row.NonChgAmt ?? 0) +
              (row.IntAmt ?? 0) +
              (row.TaxAmt ?? 0) -
              (row.Advance ?? 0)
            : 0;
        const formattedBalance = billAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formattedBalance;
      },
    },
    {
      field: "RecAmount",
      headerName: "Receipt Amount(Cr)",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        const row = params.row;
        const isBill =
          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
        const recAmount =
          !isBill && row.BillNo !== ""
            ? (row.ChgAmt ?? 0) +
              (row.NonChgAmt ?? 0) +
              (row.IntAmt ?? 0) +
              (row.TaxAmt ?? 0) +
              (row.Advance ?? 0)
            : 0;
        const formattedBalance = recAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return formattedBalance;
      },
    },

    {
      field: "CumulativeBalanceTd",
      headerName: "Balance Total",
      flex: 1,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => {
        let cumulativeBalance = Number(params.row.CumulativeBalanceTd);
        if (isNaN(cumulativeBalance)) {
          cumulativeBalance = 0;
        }

        const formattedBalance = cumulativeBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        if (cumulativeBalance < 0) {
          let absCumulative = Math.abs(cumulativeBalance);
          return (
            absCumulative.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " Cr"
          );
        } else if (cumulativeBalance === 0) {
          return formattedBalance;
        } else {
          return formattedBalance + " Dr";
        }
      },
    },
  ];
  const columnsWithDetails: GridColDef[] = [
    { field: "BillNo", headerName: "Doc No", width: 150 },
    { field: "BillDate", headerName: "Doc Date", width: 100 },
    { field: "Particulars", headerName: "Particulars", width: 150 },
    {
      field: "ChgBal",
      headerName: "BillChg",
      type: "number",
      flex: 1,
      valueGetter: (params) => {
        const billNo = params.row.BillNo || "";
        const isBill = billNo.startsWith("B") || billNo.startsWith("V");
        return isBill ? params.row.ChgAmt ?? 0 : 0;
      },
      valueFormatter: (params) =>
        params.value !== 0 ? params.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }): "--",
      headerClassName: "multi-line-header",
      renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Bill</div>
          <div>Chg</div>
        </div>
      ),},
    
    {
      field: "NonChgBal",
      headerName: " Bill NonChg",
      type: "number",
      flex: 1,
      valueGetter: (params) => {
        const billNo = params.row.BillNo || "";
        const isBill = billNo.startsWith("B") || billNo.startsWith("V");
        return isBill ? params.row.NonChgAmt ?? 0 : 0;
      },
      valueFormatter: (params) =>
        params.value !== 0 ? params.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "--",
      renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Bill</div>
          <div>NonChg</div>
        </div>
      ),},
    {
      field: "IntBal",
      headerName: " Bill Int",
      type: "number",
      flex: 1,
      valueGetter: (params) => {
        const billNo = params.row.BillNo || "";
        const isBill = billNo.startsWith("B") || billNo.startsWith("V");
        return isBill ? params.row.IntAmt ?? 0 : 0;
      },
      valueFormatter: (params) =>
        params.value !== 0 ? params.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "--",
      renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Bill</div>
          <div>Int</div>
        </div>
      ), },
    {
      field: "TaxBal",
      headerName: " Bill TaxBal",
      type: "number",
      flex: 1,
      valueGetter: (params) => {
        const billNo = params.row.BillNo || "";
        const isBill = billNo.startsWith("B") || billNo.startsWith("V");
        return isBill ? params.row.TaxAmt ?? 0 : 0;
      },
      valueFormatter: (params) =>
        params.value !== 0 ? params.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "--",
      renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Bill</div>
          <div>Tax</div>
        </div>
      ),  },
    {
      field: "ChgAmt",
      headerName: " Receipt Chg",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const isBill =
          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
        const ChgAmt = !isBill && row.BillNo !== "" ? row.ChgAmt ?? 0 : 0;
        return ChgAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },
      renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Receipt</div>
          <div>Chg</div>
        </div>
      ), },
    {
      field: "NonChgAmt",
      headerName: "Receipt NonChgAmt ",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const isBill =
          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
        const NonChgAmt = !isBill && row.BillNo !== "" ? row.NonChgAmt ?? 0 : 0;
        return NonChgAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Receipt</div>
          <div>NonChg</div>
        </div>
      ),
    },
    {
      field: "IntAmt",
      headerName: "Receipt Int ",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const isBill =
          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
        const IntAmt = !isBill && row.BillNo !== "" ? row.IntAmt ?? 0 : 0;
        return IntAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Receipt</div>
          <div>Int</div>
        </div>
      ),
    },
    {
      field: "TaxAmt",
      headerName: "Receipt Tax ",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const isBill =
          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
        const TaxAmt = !isBill && row.BillNo !== "" ? row.TaxAmt ?? 0 : 0;
        return TaxAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Receipt</div>
          <div>Tax</div>
        </div>
      ),
    },
    {
      field: "Advance",
      headerName: "Receipt Advance",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = params.value;
        let cumulativeBalance = Number(value);
        let absCumulative = Math.abs(cumulativeBalance);
        return (
          absCumulative.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
      ,renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Receipt</div>
          <div>Advance</div>
        </div>
      ),
    },
    {
      field: "ChgAmtBal",
      headerName: "Balance Chg",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = parseFloat(params.value); 
        return !isNaN(value) ? value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) : ''; 
      }
      ,renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Balance</div>
          <div>Chg</div>
        </div>
      ),
    },
    
    
    {
      field: "NonChgAmtBal",
      headerName: "Balance NonChgAmt ",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = parseFloat(params.value); // Convert to a number
        return !isNaN(value) ? value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) : ''; // Format if it's a valid number
      }
      ,renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Balance</div>
          <div>NonChgAmt</div>
        </div>
      ),
    },
    {
      field: "IntAmtBal",
      headerName: "Balance Int ",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = parseFloat(params.value); // Convert to a number
        return !isNaN(value) ? value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }): ''; // Format if it's a valid number
      }
      ,renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" ,height: "1px" }}>
          <div>Balance</div>
          <div>Int</div>
        </div>
      ),
    },
    {
      field: "TaxAmtBal",
      headerName: "Balance Tax ",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = parseFloat(params.value); // Convert to a number
        return !isNaN(value) ? value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) : ''; // Format if it's a valid number
      }
      ,renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Balance</div>
          <div>Tax</div>
        </div>
      ),
    },
    {
      field: "AdvanceBal",
      headerName: "Adv",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        const value = params.value;
        let cumulativeBalance = Number(value);
        let absCumulative = Math.abs(cumulativeBalance);
        return (
          absCumulative.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
      ,renderHeader: () => (
        <div style={{ textAlign: "center", lineHeight: "1.2em" }}>
          <div>Balance</div>
          <div>Advance</div>
        </div>
      ),
        
}

  ];
  const columns = showDetails ? columnsWithoutDetails : columnsWithDetails;

  const getBuildingsForSociety = () => {
    societyBuildingsService
      .getSelectListBySocietyId(societyId)
      .then((response) => {
        setBuildings(response.data);
      });
  };

  const getSocietyBuildingUnitbySocietyBuildingId = (
    societyBuildingId: string
  ) => {
    societyBuildingUnitsService
      .getSelectListBySocietyBuildingId(societyBuildingId)
      .then((response) => {
        setBuildingUnits(response.data);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      var model = setFormValue(values);
      societyReportService.memberLedgerReportPdf(model).then((response) => {
        let result = response.data;
        fileDownload(
          result,
          values.IsDetails
            ? "MemberLedgerDetailsReport.pdf"
            : "MemberLedgerReport.pdf"
        );
      });
    }
  };
  interface CustomToolbarProps {
    totalbalance: number;
    totalReceiptAmount: number;
    TotalNonChgBillAmount: number;
    TotalBillAmountChg: number;
    totalIntBillAmount: number;
    totalTaxBillAmount: number;
    totalChgReceiptAmount: number;
    totalNonChgReceiptAmount: number;
    totalTaxReceiptAmount: number;
    totalIntReceiptAmount: number;
    totalAdvanceReceiptAmount: number;
  }
  const CustomToolbar: React.FC<CustomToolbarProps> = ({
    totalbalance,
    totalReceiptAmount,
    TotalNonChgBillAmount,
    TotalBillAmountChg,
    totalIntBillAmount,
    totalTaxBillAmount,
    totalChgReceiptAmount,
    totalNonChgReceiptAmount,
    totalTaxReceiptAmount,
    totalIntReceiptAmount,
    totalAdvanceReceiptAmount,
  }) => {
    return (
      <div>
        <GridToolbar showQuickFilter quickFilterProps={{ debounceMs: 500 }} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          {values.SocietyBuildingId && values.IsDetails === false &&  (
            <>
          <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
            Total Bill Amount:{" "}
              {globalService.formatToIndianCurrency(totalbalance)}
            </strong>
          </Box>
          <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Receipt Amount:{" "}
              {globalService.formatToIndianCurrency(totalReceiptAmount)}
            </strong>
          </Box></>
        )}
          {values.IsDetails === true && (
            <>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                  Total Bill Amount Chg:{" "}
                  {globalService.formatToIndianCurrency(TotalBillAmountChg)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                  Total Non Chg Bill Amount{" "}
                  {globalService.formatToIndianCurrency(TotalNonChgBillAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                  Total Int Bill Balance:{" "}
                  {globalService.formatToIndianCurrency(totalIntBillAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                Total Tax Bill Balance:{" "}
                  {globalService.formatToIndianCurrency(totalTaxBillAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                Total Chg Receipt Balance:{" "}
                  {globalService.formatToIndianCurrency(totalChgReceiptAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                Total NonChg Receipt Amount{" "}
                  {globalService.formatToIndianCurrency(totalNonChgReceiptAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                Total Tax Receipt Amount{" "}
                  {globalService.formatToIndianCurrency(totalTaxReceiptAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                Total Int Receipt Amount{" "}
                  {globalService.formatToIndianCurrency(totalIntReceiptAmount)}
                </strong>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                }}
              >
                <strong>
                Total Advance Receipt Amount{" "}
                  {globalService.formatToIndianCurrency(totalAdvanceReceiptAmount)}
                </strong>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <Typography variant="h5" align="center">
        Member Ledger Report
      </Typography>
      <form
        autoComplete="off"
        noValidate
        //className={classes.root}
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <Controls.Select
                     showEmptyItem={false}
                    name="SocietyBuildingId"
                    label="For Building"
                    required
                    value={buildings.length > 0 ? values.SocietyBuildingId : ""}
                    onChange={(e: any) => {
                      getSocietyBuildingUnitbySocietyBuildingId(e.target.value);
                      handleInputChange(e);
                    }}
                    options={buildings}
                    error={errors.SocietyBuildingId}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controls.Select
                    showEmptyItem={true}
                    name="SocietyBuildingUnitId"
                    label="Building Unit"
                    required
                    value={
                      buildingUnits.length > 0
                        ? values.SocietyBuildingUnitId
                        : ""
                    }
                    onChange={handleInputChange}
                    options={buildingUnits}
                    error={errors.SocietyBuildingUnitId}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Controls.ReactDatePicker
                    label="From Date"
                    min={globalService.convertLocalToUTCDate(
                      new Date(societySubscription?.SubscriptionStart)
                    )}
                    max={globalService.convertLocalToUTCDate(
                      new Date(societySubscription?.PaidTillDate)
                    )}
                    onChange={(date: Date) =>
                      handleInputChange({
                        target: {
                          value: globalService.convertLocalToUTCDate(date),
                          name: "FromDate",
                        },
                      })
                    }
                    value={values.FromDate}
                    error={errors.FromDate}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controls.ReactDatePicker
                    label="To Date"
                    fullWidth
                    value={values.ToDate}
                    onChange={(date: Date) =>
                      handleInputChange({
                        target: {
                          value: globalService.convertLocalToUTCDate(date),
                          name: "ToDate",
                        },
                      })
                    }
                    min={values.FromDate}
                    max={globalService.convertLocalToUTCDate(
                      new Date(societySubscription?.PaidTillDate)
                    )}
                    error={errors.ToDate}
                  />
                  <Grid item xs={12} sm={16}>
                    {societySubscription && (
                      <Typography className="small-text">
                        From Date & To Date must be between{" "}
                        {dayjs(societySubscription.SubscriptionStart).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                        to{" "}
                        {dayjs(societySubscription.PaidTillDate).format(
                          "DD-MMM-YYYY"
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <Controls.Checkbox
                    name="IsDetails"
                    label="Details"
                    value={values.IsDetails}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Stack spacing={2} direction="row">
                    {isSSSociety && values.SocietyBuildingId &&
                      values.SocietyBuildingUnitId &&
                     (
                        <Button
                          className="btnGrid"
                          variant="contained"
                          size="small"
                          onClick={handleViewClick}
                        >
                          <VisibilityIcon style={{ marginRight: 4 }} />
                        </Button>
                      )}
                    {/* className={!globalService.roleMatch([ROLES.ReadOnly], auth) ? '' : 'hidden'} */}
                    <Button type="submit" variant="contained"      startIcon={<DownloadIcon />}>
                      PDF
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>
      </form>
      { isSSSociety && values.SocietyBuildingId &&
                      values.SocietyBuildingUnitId &&
                    (
        <Card>
          <CardContent>
            <React.Fragment>
              {/* {values.SocietyBuildingId && values.IsDetails === false && ( */}
              <div className="dvDataGrid">
                {/* <h4> */}
                <Typography variant="h6" align="center">
                  {unitNo} ({memberData} )
                </Typography>
                <DataGrid
                  getRowId={(row) => row.IntID}
                  rows={reportData}
                  columns={columns}
                  columnHeaderHeight={35}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {},
                    },
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  slots={{
                    toolbar: () => (
                      <CustomToolbar
                        totalbalance={reportData.reduce((acc, row) => {
                          const isBill =
                            row.BillNo &&
                            (row.BillNo[0] === "B" || row.BillNo[0] === "V");
                          const billAmount = isBill
                            ? (row.ChgAmt ?? 0) +
                              (row.NonChgAmt ?? 0) +
                              (row.IntAmt ?? 0) +
                              (row.TaxAmt ?? 0) -
                              (row.Advance ?? 0)
                            : 0;
                          return acc + billAmount;
                        }, 0)}
                        totalReceiptAmount={reportData.reduce((acc, row) => {
                          const isBill =
                            row.BillNo &&
                            (row.BillNo[0] === "B" || row.BillNo[0] === "V");

                          const recAmount =
                            !isBill && row.BillNo !== ""
                              ? (row.ChgAmt ?? 0) +
                                (row.NonChgAmt ?? 0) +
                                (row.IntAmt ?? 0) +
                                (row.TaxAmt ?? 0) +
                                (row.Advance ?? 0)
                              : 0;
                          return acc + recAmount;
                        }, 0)}
                        TotalBillAmountChg={reportData.reduce((acc, row) => {
                          const billNo = row.BillNo || "";
                          const isBill =
                            billNo.startsWith("B") || billNo.startsWith("V");
                          const ChgAmt = isBill ? row.ChgAmt ?? 0 : 0;
                          return acc + ChgAmt;
                        }, 0)}
                        TotalNonChgBillAmount={reportData.reduce((acc, row) => {
                          const billNo = row.BillNo || "";
                          const isBill =
                            billNo.startsWith("B") || billNo.startsWith("V");
                          return acc + (isBill ? row.NonChgAmt ?? 0 : 0);
                        }, 0)}
                        totalIntBillAmount={reportData.reduce((acc, row) => {
                          const billNo = row.BillNo || "";
                          const isBill =
                            billNo.startsWith("B") || billNo.startsWith("V");
                          return acc + (isBill ? row.IntAmt ?? 0 : 0);
                        }, 0)}
                        // Total TaxBal
                        totalTaxBillAmount={reportData.reduce((acc, row) => {
                          const billNo = row.BillNo || "";
                          const isBill =
                            billNo.startsWith("B") || billNo.startsWith("V");
                          return acc + (isBill ? row.TaxAmt ?? 0 : 0);
                        }, 0)}
                        totalChgReceiptAmount={reportData.reduce((acc, row) => {
                          const isBill =
                          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
                        const ChgAmt = !isBill && row.BillNo !== "" ? row.ChgAmt ?? 0 : 0;
                          return acc + (ChgAmt ? row.ChgAmt ?? 0 : 0);
                        }, 0)}
                        totalNonChgReceiptAmount={reportData.reduce((acc, row) => {
                          const isBill =
                          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
                        const NonChgAmt = !isBill && row.BillNo !== "" ? row.NonChgAmt ?? 0 : 0;
                          return acc + (NonChgAmt ? row.NonChgAmt ?? 0 : 0);
                        }, 0)}
                        totalTaxReceiptAmount={reportData.reduce((acc, row) => {
                          const isBill =
                          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
                        const TaxAmt = !isBill && row.BillNo !== "" ? row.TaxAmt ?? 0 : 0;
                          return acc + (TaxAmt ? row.TaxAmt ?? 0 : 0);
                        }, 0)}
                        totalIntReceiptAmount={reportData.reduce((acc, row) => {
                          const isBill =
                          row.BillNo && (row.BillNo[0] === "B" || row.BillNo[0] === "V");
                        const IntAmt = !isBill && row.BillNo !== "" ? row.IntAmt ?? 0 : 0;
                         return acc + (IntAmt ? row.IntAmt ?? 0 : 0);
                        }, 0)}
                        totalAdvanceReceiptAmount={reportData.reduce(
                          (acc, row) => acc + (row.Advance ?? 0),
                          0
                        )}
                      />
                    ),
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </div>
              <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate("/dashboard/" + societyId)}
                  >
                    Back
                  </Button>
                </Stack>
              </CardActions>
            </React.Fragment>
          </CardContent>
        </Card>
      )}{" "}
    </>
  );
};

export default MemberLedgerReportForm;
