import {
  Button,
  Card,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
  Box,
  IconButton,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButtonProps,
  styled,
  Collapse,
  CardHeader,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ApartmentIcon from "@mui/icons-material/Apartment";
//import PaidIcon from '@mui/icons-material/Paid';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SavingsIcon from '@mui/icons-material/Savings';
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PeopleIcon from "@mui/icons-material/People";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import { dashboardService } from "../../services/DashboardService";
import { appInfoService } from "../../services/AppInfoService";
import { globalService } from "../../services/GlobalService";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CampaignIcon from "@mui/icons-material/Campaign";
import { BarChart, BarItem, BarLabelContext, } from "@mui/x-charts";
import { DataGrid, GridColDef, GridFooterContainer, GridRow, GridToolbar } from "@mui/x-data-grid";



interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const Dashboard = () => {
  const { societySubscriptionId }: any = useParams();
  const [summary, setSummary] = useState([]);
  const [showDashboard, setShowDashboard] = React.useState({});
  const [summaryPartB, setSummaryPartB] = useState([]);
  const [acBalancesForSociety, setAcBalancesForSociety] = useState([])
  const [dashboardFeaturesList, setDashboardFeaturesList] = useState([])
  const [dashboardPGSummary, setDashboardPGSummary] = useState([])
  const [dashboardPGSummaryTable, setDashboardPGSummaryTable] = useState([])
  const [dashboardReceiptSummary, setDashboardReceiptSummary] = useState([])
  const [dashboardReceiptSummaryTable, setDashboardReceiptSummaryTable] = useState([])
  //const [billGeneratedTill, setBillGeneratedTill] = useState([]);
  const [expandFeatures, setExpandFeatures] = React.useState(false);
  const [expandPGChart, setExpandPGChart] = React.useState(false);

  const [expandReceiptChart, setExpandReceiptChart] = React.useState(false);
  const [selectedChart, setSelectedChart] = useState('bar');
  const [receiptSubPeriods, setReceiptSubPeriods] = useState([]);
  const [pgSubPeriods, setPgSubPeriods] = useState([]);
  const SocietyId: any = localStorage.getItem("societyId");
  const navigate = useNavigate();

  // const getSocieties = (SocietyId: any) => {
  //     societiesService.getById(SocietyId).then((response) => {
  //       if (response) {

  //         let result = response.data;
  //         setValues(setFormValue(result.data));
  //       }
  //     });
  //   };
  useEffect(() => {
    //call GetAppInfo and set value for setShowDashboard
    getAppInfo();
  }, []);

  const getForDashboard = (SocietyId: any) => {
    dashboardService.getForDashboardPartA(SocietyId).then((response) => {
      let result = response.data;

      //result.summary.push({ Info: "Total Investment", Tot: "1032456" })
      setSummary(result.summary);
      if (result.error) {
        globalService.error(result.error);
      }
      //setBillGeneratedTill(result.billGeneratedTill);
    });
  };

  const getForDashboardPartB = (SocietyId: any) => {
    dashboardService.getForDashboardPartB(SocietyId).then((response) => {
      let result = response.data;
      setSummaryPartB(result.summaryPartB);
      if (result.error) {
        globalService.error(result.error);
      }
    });
  };

  const getAppInfo = () => {
    appInfoService.getAppInfo().then((response) => {
      let result = response.data;
      var ShowDashboard = result.row.FlagInfo.Dashboard;
      setShowDashboard(ShowDashboard);
      if (ShowDashboard === true) {
        getForDashboard(SocietyId);
        getForDashboardPartB(SocietyId);
        getAcBalancesForSociety(SocietyId);
        getDashboardFeaturesList(SocietyId);
        // getDashboardPGSummary(SocietyId);
        getDashboardPGSummary(SocietyId);
        getDashboardReceiptSummary(SocietyId);
      }
    })
  }

  const getAcBalancesForSociety = (SocietyId: any) => {
    dashboardService.getAcBalancesForSociety(SocietyId).then((response) => {
      let result = response.data;
      setAcBalancesForSociety(result.list);
    });
  };

  const getDashboardFeaturesList = (SocietyId: any) => {
    dashboardService.getDashboardMissingFeaturesList(SocietyId).then((response) => {
      let result = response.data;
      setDashboardFeaturesList(result.missingFeaturesList);
      if (result.error) {
        globalService.error(result.error);
      }
    });
  };

  const handleNavigation = (info: any) => {
    if (info.toUpperCase() === "TOTAL BUILDINGS") {
      navigate(`/societyBuildings/${SocietyId}`);
    } else if (info.toUpperCase() === "TOTAL UNITS") {
      navigate(`/societyBuildings/${SocietyId}`);
    } else if (info.toUpperCase() === "TOTAL MEMBERS") {
      navigate(`/societyMembers/${SocietyId}`);
    }
    else if (info.toUpperCase() === "TOTAL PARKING LOTS") {
      navigate(`/societyParkings/${SocietyId}`);
    }
  };

  const categoryA = dashboardFeaturesList.filter(item => item.ShowBlock === 'A');
  const categoryB = dashboardFeaturesList.filter(item => item.ShowBlock === 'B');
  const categoryC = dashboardFeaturesList.filter(item => item.ShowBlock === 'C');

  const handleClick = (hyperLink: any) => {
    if (hyperLink) {
      window.open(hyperLink, '_self');
    }
  };

  const renderCategorySection = (title: any, items: any) => (
    items && items.length > 0 && (
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" style={{ fontSize: '1rem', marginLeft: '10px' }} >
          {title}
        </Typography>
        <Paper elevation={1} className="ds-paper">
          <Box className="db-box1">
            <Box
              className="db-box2"
              sx={{
                height: 150,
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {items.map((item: any, index: any) => (
                <Stack key={index}>
                  <Typography
                    variant="button"
                    style={{ cursor: item.HyperLink ? 'pointer' : 'default', textTransform: 'none' }}
                    onClick={() => handleClick(item.HyperLink)}
                  >
                    <li>{item.Info}</li>
                  </Typography>
                </Stack>
              ))}
            </Box>
          </Box>
        </Paper>
      </Grid>
    )
  );

  const getDashboardPGSummary = (SocietyId: any) => {
    dashboardService.getDashboardPGSummary(SocietyId).then((response) => {
      let result = response.data;
      setDashboardPGSummary(result.list);
      setPgSubPeriods(result.subPeriods);
      // Prepare rows
      const rows = result.table.map((item: any) => ({
        id: item.Mode, // Use PayModeCode as unique ID
        Mode: item.Mode,
        ...item.Amounts,
        //TotalAmount: item.TotalAmount
      }));
      setDashboardPGSummaryTable(rows);
    });
  };

  const PGSummaryChart = (prop: any) => {
    if (prop && prop.list) {
      const list = prop.list;
      const Modes = list.map((x: any) => x.Mode);
      // const subPeriods = Array.from(new Set(list.map((x: any) => x.SubPeriod)));
      // setSubPeriods(subPeriods);
      // Get distinct values using Set
      if (Modes && Modes.length > 0) {
        const xAxisData = Array.from(new Set(Modes));
        const seriesData: any[] = [];
        let filteredData: any[] = [];
        pgSubPeriods.forEach((x: any, index: number) => {
          filteredData = list.filter((y: any) => y.SubPeriod === x);
          seriesData.push({
            data: filteredData.map((x: any) => x.Amount),
            label: x,
            id: index,
            valueFormatter: globalService.yAxisTickFormatter
          })
        });
        return (
          <Grid item xs={12} sm={11} sx={{
            border: "1px solid #ccc",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Adjust height if needed
          }}>
            <BarChart
              key={"summary_" + prop.index}
              width={500}
              height={300}
              series={seriesData}
              barLabel={(item, context) => {
                return `${globalService.yAxisTickFormatter(item.value ?? 0)}`;
              }}
              xAxis={[{
                data: xAxisData, scaleType: 'band'
                // colorMap: {
                //   type: 'ordinal',
                //   // values: ['Completed', 'In Progress', 'To do'],
                //   colors: ['grey']
                // },
              }]}
              yAxis={[{ tickSize: 10, valueFormatter: globalService.yAxisTickFormatter }]} // Apply tickSize and valueFormatter
            />
          </Grid>
        );
      }
      else {
        return (
          <Grid item xs={12} sm={11} sx={{ border: "1px solid #ccc" }}>
            No Data Found
          </Grid>
        );
      }
    }
    else return (
      <Grid item xs={12} sm={11} sx={{ border: "1px solid #ccc" }}>
        No Data Found
      </Grid>
    );
  }

  const dynamicPGSummaryColumns: GridColDef[] = [
    {
      field: 'Mode', headerName: 'Pay Mode', width: 150,
    },
    ...pgSubPeriods.map(period => ({
      field: period,
      headerName: period,
      headerClassName: 'header-right',
      flex: 1,
      renderCell: (params: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          {globalService.formatToIndianCurrency(params.value ?? 0)}
        </div>
      ),
    })),
  ];


  const CustomPGSummaryFooter = () => {
    return (
      <GridFooterContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '2px', borderTop: '1px solid #e0e0e0' }}>
          <Box key="total" sx={{ width: 150, textAlign: "center" }}><strong>Total:</strong></Box>
          {pgSubPeriods.map(period => (
            <Box key={period} sx={{ flex: 1, textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
              <strong> {globalService.formatToIndianCurrency(dashboardPGSummaryTable.reduce((acc, row) => acc + row[period], 0) ?? 0)}</strong>
            </Box>
          )
          )}
        </Box>
      </GridFooterContainer >
    );
  };

  // const getDashboardPGSummary = (SocietyId: any) => {
  //   dashboardService.getDashboardPGSummary(SocietyId).then((response) => {
  //     let result = response.data.list;
  //     const chartData = result.map((item: any) => ({
  //       label: item.Mode,
  //       value: parseFloat(item.Amount),
  //     }));
  //     setDashboardPGSummary(chartData);
  //   });
  // };

  const labels = dashboardPGSummary.map((item) => item.label);
  const values = dashboardPGSummary.map((item) => item.value);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = (section: string) => {
    if (section === "expand_feature") {
      setExpandFeatures((prev) => !prev);
    }
    else if (section === "expand_pgchart") {
      setExpandPGChart((prev) => !prev);
    }
    else if (section === "expand_receiptchart") {
      setExpandReceiptChart((prev) => !prev);
    }
  };

  const getDashboardReceiptSummary = (SocietyId: any) => {
    dashboardService.getDashboardReceiptSummary(SocietyId).then((response) => {
      
      let result = response.data;
      setDashboardReceiptSummary(result.list);
      //setSubPeriods(Array.from(new Set(result.list.map((x: any) => x.SubPeriod))));
      setReceiptSubPeriods(result.subPeriods);
      // Prepare rows
      const rows = result.table.map((item: any) => ({
        id: item.PayModeCode, // Use PayModeCode as unique ID
        PayModeCode: item.PayModeCode,
        ...item.Amounts,
        //TotalAmount: item.TotalAmount
      }));

      setDashboardReceiptSummaryTable(rows);
    });
  };

  // Extract unique SubPeriods (dynamic columns)
  // Prepare columns
  const dynamicReceiptColumns = [
    { field: 'PayModeCode', headerName: 'Pay Mode', width: 150 },
    ...receiptSubPeriods.map(period => ({
      field: period,
      headerName: period,
      headerClassName: 'header-right',
      // renderHeader: () => (
      //   <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', }}>
      //     <strong>{period}</strong>
      //   </div>
      // ),
      flex: 1,
      renderCell: (params: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          {globalService.formatToIndianCurrency(params.value ?? 0)}
        </div>
      ),
    })),
    // {
    //   field: 'TotalAmount', headerName: 'Total Amount', flex: 1,
    //   renderCell: (params: any) => (
    //     <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
    //       {globalService.formatToIndianCurrency(params.value ?? 0)}
    //     </div>
    //   ),
    // }
  ];

  const CustomReceiptFooter = () => {
    return (
      <GridFooterContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '2px', borderTop: '1px solid #e0e0e0' }}>
          <Box key="total" sx={{ width: 150, textAlign: "center" }}><strong>Total:</strong></Box>
          {receiptSubPeriods.map(period => (
            <Box key={period} sx={{ flex: 1, textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
              <strong> {globalService.formatToIndianCurrency(dashboardReceiptSummaryTable.reduce((acc, row) => acc + row[period], 0) ?? 0)}</strong>
            </Box>
          )
          )}
        </Box>
      </GridFooterContainer >
    );
  };


  const ReceiptSummaryChart = (prop: any) => {
    if (prop && prop.list) {
      const list = prop.list;
      const payModes = list.map((x: any) => x.PayModeCode);
      //const subPeriods = Array.from(new Set(list.map((x: any) => x.SubPeriod)));
      // Get distinct values using Set
      if (payModes && payModes.length > 0) {
        const xAxisData = Array.from(new Set(payModes));
        const seriesData: any[] = [];
        let filteredData: any[] = [];
        receiptSubPeriods.forEach((x: any, index: number) => {
          filteredData = list.filter((y: any) => y.SubPeriod === x);
          seriesData.push({
            data: filteredData.map((x: any) => x.Amount),
            label: x,
            id: index,
            valueFormatter: globalService.yAxisTickFormatter,
            //barLabel: true, // Enable labels

          })
        });
        return (
          <Grid item xs={12} sm={11} sx={{
            border: "1px solid #ccc",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Adjust height if needed
          }}>
            <BarChart
              key={"summary_" + prop.index}
              width={500}
              height={300}
              series={seriesData}
              barLabel={(item, context) => {
                return `${globalService.yAxisTickFormatter(item.value ?? 0)}`;
              }}

              xAxis={[{
                data: xAxisData, scaleType: 'band',

                // colorMap: {
                //   type: 'ordinal',
                //   // values: ['Completed', 'In Progress', 'To do'],
                //   colors: ['grey']
                // },
              }]}
              yAxis={[{ tickSize: 10, valueFormatter: globalService.yAxisTickFormatter }]} // Apply tickSize and valueFormatter
            />
          </Grid>
        );
      }
      else {
        return (<> No Data Found</>);
      }
    }
    else return (<> No Data Found</>);
  }

  const chartTypeList = [
    { Value: 'pie', Text: 'Pie chart' },
    { Value: 'bar', Text: 'Bar chart' },
  ];

  const handleInputChange = (e: any) => {
    setSelectedChart(e.target.value);
  };

  return (
    <>
      {showDashboard === true ? (
        <>
          <Typography variant="h5" align="center">
            Dashboard
          </Typography>
          <Grid container spacing={1} >
            {/* First Card */}
            {summary.map((item, index) => (
              <Grid item xs={12} sm={2.4} key={"1_" + index}
                onClick={() => handleNavigation(item.Info)}
                style={{ cursor: 'pointer' }}>
                <Paper elevation={1} className="ds-paper">
                  <Box>
                    <Box className="db-box1">
                      <Grid container className="db-grid1">
                        <Grid item xs={9} key={"2_" + index}>
                          <Box className="db-box2">
                            <Typography
                              variant="button"
                              className="db-textHeader"
                            >
                              {item.Info}
                            </Typography>
                            <Typography variant="h6" className="db-textTotal">
                              {item.Info.toUpperCase() === "INVESTMENT MATURING" ? globalService.formatToIndianCurrency(item.Tot) : item.Tot}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} className="css-1pdvnt5">
                          <Box className="css-19ioz2v">
                            <IconButton>
                              {item.Info.toUpperCase() === "TOTAL BUILDINGS" && (
                                <ApartmentIcon />
                              )}
                              {item.Info.toUpperCase() ===
                                "TOTAL UNITS" && <ViewModuleIcon />}
                              {item.Info.toUpperCase() === "TOTAL MEMBERS" && (
                                <PeopleIcon />
                              )}
                              {item.Info.toUpperCase() ===
                                "TOTAL PARKING LOTS" && (
                                  <EmojiTransportationIcon />
                                )}
                              {item.Info.toUpperCase() ===
                                "INVESTMENT MATURING" && (
                                  <SavingsIcon />
                                )}
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
            {/* Repeat the structure for other cards */}
          </Grid>

          <Typography variant="h6" align="center" >
            Bills Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ overflowX: "auto" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Bill Abbreviation</TableCell>
                        <TableCell >Bills Generated Till</TableCell>
                        <TableCell className="muiTableCell-right">Monthly Maintenance</TableCell>
                        <TableCell className="muiTableCell-right">Outstanding Amount</TableCell>
                        <TableCell className="muiTableCell-right">Advance Amount</TableCell>
                        {/* <TableCell align="right">Balance Amount</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summaryPartB.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.BillAbbreviation}</TableCell>
                          <TableCell>{row.BillGeneratedTill}</TableCell>
                          <TableCell className="muiTableCell-right">{globalService.formatToIndianCurrency(row.TotMonthlyMaintenance ?? 0)}</TableCell>
                          <TableCell className="muiTableCell-right">
                            <Link href={"/memberBalanceReport/" + SocietyId + "/" + societySubscriptionId} target="_blank">
                              {globalService.formatToIndianCurrency(row.OutStandingBal ?? 0)}
                            </Link>
                          </TableCell>
                          <TableCell className="muiTableCell-right">{globalService.formatToIndianCurrency(row.AdvanceBal ?? 0)}</TableCell>
                          {/* <TableCell>{row.Balance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</TableCell> */}
                          {/* <TableCell align="right">{`$${row.Tot}`}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Paper>
            </Grid>
            {/* Repeat the structure for other cards */}
          </Grid>

          {/* Cash And Bank Balances */}
          <>
            <Typography variant="h6" align="center" >
              Cash And Bank Balances
            </Typography>
            <Grid container spacing={1} marginTop={0}>
              {acBalancesForSociety.map((item, index) => (
                <Grid item xs={12} sm={3} key={"1_" + index}>
                  <Paper elevation={1} className="db-color-box css-qipk4m">
                    <Box>
                      <Box className="db-box1">
                        <Grid container className="db-grid1">
                          <Grid item xs={12} key={"2_" + index}>
                            <Box className="db-box2" color="red">
                              <Typography
                                variant="button"
                                className="db-textHeader"
                              >
                                {item.AcHead}
                              </Typography>
                              <Typography variant="h5" className="db-textTotal" color={item.Balance < 0 ? "red" : "text-grey"}>
                                {globalService.formatToIndianCurrency(item.Balance ?? 0)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </>

          {/* Features */}
          <Card sx={{ marginTop: '10px' }}>
            <CardHeader
              avatar={
                <CampaignIcon />
              }
              title="Unused Features"
              titleTypographyProps={{ variant: 'h6', align: 'center' }} // Centers the title
              action={
                <>
                  <ExpandMore
                    expand={expandFeatures}
                    onClick={() => handleExpandClick("expand_feature")}
                    aria-expanded={expandFeatures}
                    aria-label="show more"
                    style={{ position: 'absolute', right: 30 }}
                  >
                    {expandFeatures ? <RemoveIcon /> : <AddIcon />}
                  </ExpandMore>
                </>
              }
            />
            <Collapse in={expandFeatures} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container spacing={2}>
                  {renderCategorySection("To Facilitate Easy Payment", categoryA)}
                  {renderCategorySection("Good to use features", categoryB)}
                  {renderCategorySection("Update Details", categoryC)}
                </Grid>
              </CardContent>
            </Collapse>
          </Card>

          {/* BarChart */}
          <Card sx={{ marginTop: '10px' }}>
            <CardHeader
              title="Receipt Summary"
              titleTypographyProps={{ align: 'center' }} // Centers the title
              action={
                dashboardReceiptSummary && dashboardReceiptSummary.length > 0 && <ExpandMore
                  expand={expandReceiptChart}
                  onClick={() => handleExpandClick("expand_receiptchart")}
                  aria-expanded={expandReceiptChart}
                  aria-label="show more"
                  style={{ position: 'absolute', right: 30 }}
                >
                  {expandReceiptChart ? <RemoveIcon /> : <AddIcon />}
                </ExpandMore>
              }
            />
            <Collapse in={expandReceiptChart} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container justifyContent="center" alignItems="right" key="chartReceiptSummary">
                  <Grid item xs={12} sm={7} sx={{
                    //border: "1px solid #ccc",
                    //margin: "-10px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', // Adjust height if needed
                  }}>
                    {
                      dashboardReceiptSummary && dashboardReceiptSummary.length > 0 &&
                      <ReceiptSummaryChart key="barChartReceipt" list={dashboardReceiptSummary} />
                    }
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div>
                      <DataGrid
                        getRowId={(row) => row.id}
                        rows={dashboardReceiptSummaryTable}
                        columns={dynamicReceiptColumns}
                        columnHeaderHeight={30}
                        //rowHeight={30}
                        autoHeight={true}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}

                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns Id, the other columns will remain visible
                              //AcHeadId: false,
                            },
                          },
                          //pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        slots={{
                          //toolbar: GridToolbar,
                          footer: CustomReceiptFooter, // Use the 'slots' prop to customize the footer
                          //pagination is not working with this footer. Need to research
                        }}
                      />
                    </div>
                    {/* <Typography variant="body1" align="right">
                    <b>Grand Total: </b>{globalService.formatToIndianCurrency(dashboardReceiptSummaryTable.map((t: any) => t.TotalAmount).reduce((prev: any, next: any) => prev + next, 0))}
                  </Typography> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>

          {/* PieChart */}

          <Card>
            <CardHeader
              title="Collections using Payment Gateway using different Pay Modes"
              titleTypographyProps={{ align: 'center' }} // Centers the title
              action={
                dashboardPGSummary.length > 0 && <ExpandMore
                  expand={expandPGChart}
                  onClick={() => handleExpandClick("expand_pgchart")}
                  aria-expanded={expandPGChart}
                  aria-label="show more"
                  style={{ position: 'absolute', right: 30 }}
                >
                  {expandPGChart ? <RemoveIcon /> : <AddIcon />}
                </ExpandMore>
              }
            />

            <Collapse in={expandPGChart} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container justifyContent="center" alignItems="right" key="chartPGSummary">
                  <Grid item xs={12} sm={7} sx={{
                    //border: "1px solid #ccc",
                    //margin: "-10px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', // Adjust height if needed
                  }}>
                    {dashboardPGSummary && dashboardPGSummary.length > 0 &&
                      <PGSummaryChart key="barChartPG" list={dashboardPGSummary} />
                    }
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <div>
                      <DataGrid
                        getRowId={(row) => row.id}
                        rows={dashboardPGSummaryTable}
                        columns={dynamicPGSummaryColumns}
                        columnHeaderHeight={30}
                        //rowHeight={30}
                        autoHeight={true}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}

                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns Id, the other columns will remain visible
                              //AcHeadId: false,
                            },
                          },
                          //pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        slots={{
                          //toolbar: GridToolbar,
                          footer: CustomPGSummaryFooter, // Use the 'slots' prop to customize the footer
                          //pagination is not working with this footer. Need to research
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* {dashboardPGSummary.length > 0 && (
                  <div>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={2}>
                        <Controls.Select
                          name="chartType"
                          label="Select Chart Type"
                          value={selectedChart}
                          onChange={handleInputChange}
                          options={chartTypeList}
                        />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
                      {selectedChart === 'pie' && (
                      //   <PieChart
                      //   series={[
                      //     {
                      //       data: dashboardPGSummary,
                      //     },
                      //   ]}
                      //   width={300}
                      //   height={300}
                      // />
                      <PieChart width={680} height={350}>
                      <Pie
                        data={dashboardPGSummary}
                        dataKey="value"
                        nameKey="label" 
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label={({ value, percent }) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2 })} (${(percent * 100).toFixed(2)}%)`}
                      >
                        {dashboardPGSummary.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        layout="horizontal"
                        align="center"
                        verticalAlign="bottom"
                        formatter={(value) => `${value}`}
                      />
                    </PieChart>                    
                    )}

                      {selectedChart === 'bar' && (
                        <BarChart
                          xAxis={[{ scaleType: 'band', data: labels }]}
                          series={[{ data: values }]}
                          width={500}
                          height={300}
                        />
                      )}
                    </Grid>
                  </div>
                )} */}
              </CardContent>
            </Collapse>
          </Card>

          <Stack
            spacing={2}
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: 2 }}
          >
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/societySubscriptions/" + SocietyId)}
            >
              Back To Subscriptions
            </Button>
          </Stack>
        </>
      ) : <></>
      }
    </>
  );
};

export default Dashboard;