import { Box, Button, Card, CardActions, CardContent, Grid, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Controls from '../../utility/controls/Controls';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MemberBalanceReportModel } from '../../models/SocietyReportModel';
import { societyReportService } from '../../services/SocietyReportService';
import useForm from '../../utility/hooks/UseForm';
import { SelectListModel } from '../../models/ApiResponse';
import { societyBuildingsService } from '../../services/SocietyBuildingsService';
import DownloadIcon from '@mui/icons-material/Download';
import { globalService } from '../../services/GlobalService';
import fileDownload from 'js-file-download';
import { societyBillSeriesService } from '../../services/SocietyBillSeriesService';
import { Messages } from '../../utility/Config';
import { useSharedNavigation } from '../../utility/context/NavigationContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
let isSSSociety = process.env.REACT_APP_BASE_URL.includes("sssociety");

const MemberBalanceReportForm = () => {
  const { societyId, societySubscriptionId }: any = useParams();
  let navigate = useNavigate();
  let balanceFilters = globalService.getBalanceFilterForReport();
  const { goToHome } = useSharedNavigation();
  const [buildings, setBuildings] = useState<SelectListModel[]>([]);
  const [billAbbreviations, setBillAbbreviations] = useState<SelectListModel[]>([]);
  //const [societySubscription, setSocietySubscription] = useState<SocietySubscriptionsModel>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [reportData, setReportData] = useState([]);
  const validate = (fieldValues: MemberBalanceReportModel = values) => {
    let temp: any = { ...errors };
    if ("BillAbbreviation" in fieldValues) {
      if (fieldValues.BillAbbreviation === "") {
        // The user selected "All"
        temp.BillAbbreviation = "";  // Set to empty string or handle as needed
      } else {
        // The user selected a specific value
        temp.BillAbbreviation = fieldValues.BillAbbreviation
          ? ""
          : "Bill Abbreviation is required.";
      }
    }

    if ("BalanceFilter" in fieldValues)
      temp.BalanceFilter = fieldValues.BalanceFilter ? "" : "Balance Filter is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(societyReportService.initialMemberBalanceReportFieldValues, validate, societyId);

  useEffect(() => {
    if (!globalService.isSocietySelected()) {
      globalService.info(Messages.SocietyUnSelected);
      return goToHome();
    }
    if (buildings.length === 0)
      getBuildingsForSociety();
    if (billAbbreviations.length === 0)
      getBillAbbreviationsBySocietyId();
  }, []);

  const newReport = () => {
    setValues(societyReportService.initialMemberBalanceReportFieldValues);
  };

  //This is used since in get the null property is not returned
  function setFormValue(model: MemberBalanceReportModel) {
    let newValue = {
      SocietyId: societyId,
      SocietySubscriptionId: societySubscriptionId,
      SocietyBuildingId: model.SocietyBuildingId,
      Amount: model.Amount,
      BalanceFilter: model.BalanceFilter,
      BillAbbreviation: model.BillAbbreviation,
      IsDetails: model.IsDetails || false,
    }
    return newValue;
  }

  const getBuildingsForSociety = () => {
    societyBuildingsService.getSelectListBySocietyId(societyId)
      .then((response) => {
        setBuildings(response.data);
      });
  };

  const getBillAbbreviationsBySocietyId = () => {
    societyBillSeriesService.getSelectListBySocietyId(societyId)
      .then((response) => {
        // setBillAbbreviations(response.data);
        const BillAbbreviationsWithAll = [{ Value: " ", Text: "All" }, ...response.data];
        setBillAbbreviations(BillAbbreviationsWithAll);
        if (response.data.length > 0)
            values.BillAbbreviation = " ";
      });
  };

  const handleViewClick = () => {
    var model = setFormValue(values);
    societyReportService.memberBalanceReportlist(model).then((response) => {
      const result = response.data;
      setReportData(result.list);
      setShowDetails(!values.IsDetails);
    })
  };

  //const handleSubmit = (e: React.FormEvent) => {
  const downloadReport = (type: string) => {
    //e.preventDefault();
    if (validate()) {
      var model = setFormValue(values);
      if (type === 'pdf') {
        societyReportService.memberBalanceReportPdf(model).then((response) => {
          let result = response.data;
          fileDownload(result, values.IsDetails ? "MemberBalanceDetailsReport.pdf" : "MemberBalanceReport.pdf");
        });
      }
      else {
        societyReportService.memberBalanceReportExcel(model).then((response) => {
          let result = response.data;
          fileDownload(result, "MemberBalanceReport.xlsx");
        });
      }
    }
  }

  const columnsWithoutDetails: GridColDef[] = [
    { field: 'BuildingUnit', headerName: 'Unit No.', flex: 1 },
    { field: 'Member', headerName: 'Members Name', flex: 1 },
    { field: 'ChgBal', headerName: 'Chg Bal', flex: 1,align:'right',headerAlign:'right'  },
    { field: 'NonChgBal', headerName: 'Non Chg Bal', flex: 1,align:'right',headerAlign:'right'  },
    { field: 'IntBal', headerName: 'IntBal', flex: 1,align:'right',headerAlign:'right'  },
    { field: 'TaxBal', headerName: 'Tax Balance', flex: 1,align:'right',headerAlign:'right'  },
    { field: 'Advance', headerName: 'Advance', flex: 1,align:'right',headerAlign:'right' },
    { field: 'Balance', headerName: 'Balance', flex: 1,align:'right',headerAlign:'right',
      renderCell: (params) => {
        return ((params.row.ChgBal)+(params.row.NonChgBal)+(params.row.IntBal)+(params.row.TaxBal) - params.row.Advance)
       }
     },
  ];

  const columnsWithDetails: GridColDef[] = [
    { field: 'BuildingUnit', headerName: 'Unit No.', flex: 1 },
    { field: 'Member', headerName: 'Members Name', flex: 1 },
    { field: 'Balance', headerName: 'Balance', flex: 1,align:'right',headerAlign:'right',
      renderCell: (params) => {
        return ((params.row.ChgBal)+(params.row.NonChgBal)+(params.row.IntBal)+(params.row.TaxBal) - params.row.Advance)
       }
     },
  ];

  const columns = showDetails ? columnsWithDetails : columnsWithoutDetails;

  interface CustomToolbarProps {
    totalChgBal: number;
    totalNonChgBal: number;
    totalIntBal: number;
    totalTaxBal: number;
    totalAdvance: number;
    totalBalance: number;
    isDetails: boolean;
  }
  const CustomToolbar: React.FC<CustomToolbarProps> = ({
    totalChgBal,
    totalNonChgBal,
    totalIntBal,
    totalTaxBal,
    totalAdvance,
    totalBalance,
    isDetails,
  }) => {
    // const balance = totalChgBal ;
    return (
      <div>
        
        <GridToolbar showQuickFilter quickFilterProps={{ debounceMs: 500 }} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          {isDetails && totalChgBal > 0 && (
          <Box
            sx={{
              flex: 10,
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Chg Bal:{" "}
              {globalService.formatToIndianCurrency(totalChgBal)}
            </strong>
            </Box>
          )}
            {isDetails && totalNonChgBal > 0 && (
            <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Non Chg Bal:{" "}
              {globalService.formatToIndianCurrency(totalNonChgBal)}
            </strong>
            </Box>
            )}
            {isDetails && totalIntBal > 0 && (
            <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Int Bal:{" "}
              {globalService.formatToIndianCurrency(totalIntBal)}
            </strong>
            </Box>
            )}
            {isDetails && totalTaxBal > 0 && (
            <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Tax Bal:{" "}
              {globalService.formatToIndianCurrency(totalTaxBal)}
            </strong>
            </Box>
            )}
            {isDetails && totalAdvance > 0 && (
            <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Advance:{" "}
              {globalService.formatToIndianCurrency(totalAdvance)}
            </strong>
            </Box>
            )}
            <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <strong>
              Total Balance:{" "}
              {globalService.formatToIndianCurrency(totalBalance)}
            </strong>
            </Box>
          </div>
      </div>
    );
  };
  return (
    <>
      <Typography variant="h5" align="center">
        Member Balance Report
      </Typography>
      <form
        autoComplete="off"
        noValidate
      //className={classes.root}
      // onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    showEmptyItem={false}
                    name="BalanceFilter"
                    label="Balance"
                    required
                    value={balanceFilters.length > 0 ? values.BalanceFilter : ""}
                    onChange={handleInputChange}
                    options={balanceFilters}
                    error={errors.BalanceFilter}
                  />
                </Grid>
                {values.BalanceFilter === "All" && <Grid item xs={12} sm={3}>
                  <Controls.Input
                    name="Amount"
                    label="Balance Above"
                    type="number"
                    value={values.Amount}
                    onChange={handleInputChange}
                    error={errors.Amount}
                  />
                </Grid>
                }
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    showEmptyItem={true}
                    name="SocietyBuildingId"
                    label="For Building"
                    required
                    value={buildings.length > 0 ? values.SocietyBuildingId : ""}
                    onChange={(e: any) => {
                      //getSocietyBuildingUnitbySocietyBuildingId(e.target.value);
                      handleInputChange(e);
                    }}
                    options={buildings}
                    error={errors.SocietyBuildingId}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    showEmptyItem={false}
                    name="BillAbbreviation"
                    label="Bill Abbreviation"
                    required
                    value={billAbbreviations.length > 0 ? values.BillAbbreviation : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    options={billAbbreviations}
                    error={errors.BillAbbreviation}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controls.Checkbox
                    name="IsDetails"
                    label="Show Balances In Details"
                    value={values.IsDetails}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
            {isSSSociety &&
              <Button
                className="btnGrid"
                variant="contained"
                size="small"
                onClick={handleViewClick}
              >
                <VisibilityIcon style={{ marginRight: 4 }} />
              </Button>
              }
              <Button type="button" startIcon={<DownloadIcon />} variant="contained" onClick={(e) => downloadReport('pdf')}>
                PDF
              </Button>

              <Button type="button" startIcon={<DownloadIcon />} variant="contained" color='success' onClick={(e) => downloadReport('excel')}>
                Excel
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/dashboard/" + societyId)}
              >
                Back
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
      {isSSSociety &&
      <div className='dvDataGrid'>
        <Typography variant="h6" component="h2">
          Member Balance Report Details
        </Typography>
        <DataGrid
          getRowId={(row) => row.SocietyBuildingUnitID}
          rows={reportData}
          columns={columns}
          columnHeaderHeight={30}
          autoHeight={true}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          initialState={{
            columns: {
              columnVisibilityModel: {

              },
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{
            // toolbar: GridToolbar
            toolbar: () => (
              <CustomToolbar
                totalChgBal={reportData.reduce(
                  (acc, row) => acc + (row.ChgBal ?? 0),
                  0
                )}

                totalNonChgBal={reportData.reduce(
                  (acc, row) => acc + (row.NonChgBal ?? 0),
                  0
                )}
                totalIntBal={reportData.reduce(
                  (acc, row) => acc + (row.IntBal ?? 0),
                  0
                )}
                totalTaxBal={reportData.reduce(
                  (acc, row) => acc + (row.TaxBal ?? 0),
                  0
                )}
                totalAdvance={reportData.reduce(
                  (acc, row) => acc + (row.Advance ?? 0),
                  0
                )}
                totalBalance={reportData.reduce(
                  (acc, row) => acc + ((row.ChgBal ?? 0)+(row.NonChgBal ?? 0)+(row.IntBal ?? 0)+(row.TaxBal ?? 0)-(row.Advance ?? 0)),
                  0
                )}
                isDetails={values.IsDetails}
                
              />
            ),

          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </div>
}
    </>
  )
}

export default MemberBalanceReportForm